/** @jsx jsx */

import { Button, Heading, jsx } from "theme-ui"
import { ImageCard, ViewElement } from "./atoms"
import { ContentBlock } from "./page"
import { Trans, useTranslation } from "react-i18next"
import { Link } from "./nav"
import { useLocalisedContentArray } from "./featured"
import { useTextDirection } from "../i18n"
import { useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
  faCircle as faCircleSolid,
} from "@fortawesome/free-solid-svg-icons"
import { faCircle as faCircleHollow } from "@fortawesome/free-regular-svg-icons"

type HeaderImage = {
  featuredImage: File
  link: string
  translations: {
    locale: string
    title: string
    abstract: string
  }[]
}

const CarouselImage: ViewElement<{
  headerImage: HeaderImage
  defaultImage: File
  current: boolean
}> = ({ headerImage, defaultImage, current }) => {
  const {
    i18n: { language },
  } = useTranslation()

  const defaultLocale = "en"

  if (!headerImage) return <div></div>

  const { title, abstract } = headerImage.translations
    ? headerImage.translations.find(t => t.locale == language) ||
      headerImage.translations.find(t => t.locale == defaultLocale) ||
      headerImage.translations[0]
    : { title: undefined, abstract: undefined }

  return (
    <ImageCard
      sx={{
        height: ["50vh", "65vh", "75vh"],
        minWidth: "100vw",
        display: "flex",
      }}
      src={
        headerImage.featuredImage?.childImageSharp ||
        defaultImage.childImageSharp
      }
      childWrapperStyle={{ width: "100%", display: "flex" }}
    >
      <ContentBlock sx={{ alignContent: "end" }}>
        {title && (
          <Heading
            variant="headings.1"
            sx={{
              width: ["null", null, "50%"],
              mb: [4, null, 4],
              mt: [8, null, 7],
              color: "#FFFFFF",
            }}
          >
            {title}
          </Heading>
        )}

        {headerImage.link && abstract && (
          <Link to={headerImage.link} tabIndex={current ? 0 : -1}>
            <Button
              sx={{
                width: ["100%", null, "auto"],
                fontWeight: 600,
                color: "#000",
                backgroundColor: "#FC164B",
                marginBottom: "30px",
              }}
              variant="primary"
              tabIndex={-1}
            >
              {abstract}
            </Button>
          </Link>
        )}
      </ContentBlock>
    </ImageCard>
  )
}

const HomepageCarousel: ViewElement<{
  headerImages: HeaderImage[]
  defaultImage: File
}> = ({ headerImages, defaultImage }) => {
  const [currentImage, setCurrentImage] = useState(0)
  const currentImageRef = useRef(0)
  const [lastManualClick, setLastManualClick] = useState(0)

  const changeCurrentImage = (input: number) => {
    const tempCurrentImage = currentImageRef.current + input

    if (tempCurrentImage < 0) {
      setCurrentImage(headerImages.length - 1)
    } else if (tempCurrentImage >= headerImages.length) {
      setCurrentImage(0)
    } else {
      setCurrentImage(tempCurrentImage)
    }
  }

  useEffect(() => {
    currentImageRef.current = currentImage
  }, [currentImage])

  useEffect(() => {
    const autoscroll =
      headerImages.length > 1
        ? setInterval(() => {
            changeCurrentImage(1)
          }, 7000)
        : undefined

    return () => {
      if (autoscroll) {
        clearInterval(autoscroll)
      }
    }
  }, [lastManualClick])

  return (
    <div sx={{ position: "relative" }}>
      <div
        sx={{
          height: ["50vh", "65vh", "75vh"],
          position: "relative",
          display: "flex",
          transition: "margin-left 0.5s ease-in-out",
          marginLeft: currentImage * -100 + "vw",
        }}
      >
        {headerImages.map((headerImage, index) => (
          <CarouselImage
            headerImage={headerImage}
            defaultImage={defaultImage}
            current={index == currentImage}
          />
        ))}
      </div>
      {headerImages.length > 1 && (
        <div
          sx={{
            position: "absolute",
            left: "50%",
            top: "calc(100% - 40px)",
            transform: "translateX(-50%)",
            zIndex: 100,
          }}
        >
          {headerImages.map((headerImage, index) =>
            index == currentImage ? (
              <FontAwesomeIcon
                icon={faCircleSolid}
                sx={{ color: "red", margin: "5px", height: "15px" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faCircleHollow}
                sx={{
                  color: "red",
                  margin: "5px",
                  height: "15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCurrentImage(index)
                  setLastManualClick(Date.now())
                }}
              />
            )
          )}
        </div>
      )}
    </div>
  )
}

export default HomepageCarousel
